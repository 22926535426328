import { Footer } from "antd/lib/layout/layout";
import { Helmet } from "react-helmet-async";
import TheFooter from "../../components/Footer/TheFooter";
import "./style.css";

const PrexCode = () => {
  return (
    <div className="content-container" style={{ height: "100vh" }}>
      <Helmet>
        <title>Pirex Code | Pirex Games</title>
      </Helmet>
      <div className="main-content">
        <div className="img-code-editor"></div>
        <div className="download-prexcode">
          <div className="dowload-prexcode-title">
            <h1> PIREX CODE EDITOR </h1>
            <h2> Free. Built on open source. Now for window. </h2>
          </div>
          <div
            className="btn-download"
            onClick={() => {
              window.open(
                "https://github.com/nguyenphuc1040/pirex-code/releases/download/v1.0.0/PirexCode.Setup.0.0.1.exe"
              );
            }}
          >
            <h2> Dowload for Window </h2>
            <h4> Preview Build </h4>
          </div>
        </div>
      </div>
      <Footer>
        <TheFooter />
      </Footer>
    </div>
  );
};

export default PrexCode;
