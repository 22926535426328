import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router";
import PrivacyPolicy from "../screens/PrivacyPolicy/PrivacyPolicyScreen";
import GamesScreen from "../screens/Games/GamesScreen";
import AboutUs from "../screens/AboutUs/AboutUs";
import PrexCode from "../screens/PrexCode/PrexCode";

import "./style.css";
import "../styles/styleGlobal.css";
import games from "../assets/data/Games.json";
import axios from "axios";
import { GameDetailType } from "../types";
import ScraperScreen from "../screens/Scraper/ScraperScreen";

function RootNav() {
  let location = useLocation();
  const [listGameDetail, setListGameDetail] = useState<GameDetailType[]>([]);
  const getListDetail = async () => {
    const listRequest = await games.map((game, index) => {
      return axios.get(game.urlReqDetail);
    });
    Promise.all(listRequest).then((values) => {
      const result = values.map((value) => value.data);
      setListGameDetail(result);
    });
  };
  useEffect(() => {
    getListDetail();
  }, []);
  return (
    <div className="content" style={{ background: "var(--backDark-color)" }}>
      <Routes location={location}>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/pirex-code" element={<PrexCode />} />
        <Route path="/who-we-are" element={<AboutUs />} />
        <Route path="/scraper/:bundleId" element={<ScraperScreen />} />
        <Route path="/" element={<GamesScreen listGames={listGameDetail} />} />
        <Route path="*" element={<GamesScreen listGames={listGameDetail} />} />
      </Routes>
    </div>
  );
}

export default RootNav;
