import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GameDetailType, GameType } from "../../types";
import GameItem from "../../components/GameItem/GameItem";
import "./style.css";
import "../../styles/styleGlobal.css";
import TheFooter from "../../components/Footer/TheFooter";
import { Footer } from "antd/lib/layout/layout";

function ScraperScreen() {
  let slug: any = {};
  slug = useParams();
  const [gameDetailInfo, setGameDetailInfo] = useState<GameDetailType>(
    {} as GameDetailType
  );
  const [game, setGame] = useState<GameType>({} as GameType);
  const getGame = () => {
    axios
      .get(
        "https://google-play-scraper-nodejs.vercel.app/cors/api/apps/" +
          slug.bundleId
      )
      .then((res) => {
        setGameDetailInfo(res.data);
        setGame({
          gameName: res.data.title,
          summary: res.data.description,
          genre: res.data.genre || "",
          platform: "",
          urlIcon: res.data.icon || "",
          urlReqDetail: res.data.urlReqDetail || "",
          urlTrailer: res.data.video || "",

          urlAndroid: res.data.playstoreUrl,
          urlIOS: "",
          urlPoster: res.data.headerImage || "",
        });
      });
  };
  useEffect(() => {
    getGame();
  }, []);

  return (
    <div className="content-container">
      <div className="ourgames-container">
        <div className="list-game-carousel-container">
          <GameItem
            gameDetailInfo={gameDetailInfo}
            game={game}
            url={gameDetailInfo.video || ""}
          ></GameItem>
        </div>
      </div>
      <Footer>
        <TheFooter />
      </Footer>
    </div>
  );
}

export default ScraperScreen;
