import "./style.css";
import { GameType, GameDetailType } from "../../types/index";
import React from "react";
import DetailGame from "../ModalDetailGame/DetailGame";
import {
  AppGalleryButton,
  AppStoreButton,
  GooglePlayButton,
} from "react-mobile-app-button";
import Smoke from "../Smoke/Smoke";

interface GameDataType {
  game: GameType | undefined;
  url: string;
  gameDetailInfo: GameDetailType;
}

function GameItem({ game, url, gameDetailInfo }: GameDataType) {
  return (
    <div className={"game-item-carousel"}>
      <div className="game-item-content">
        <div className="game-item-bg">
          <img src={game?.urlPoster} />
          <div className="game-item-smoke">
            <Smoke></Smoke>
            <Smoke></Smoke>
          </div>
        </div>
        <div className="game-item-image">
          <DetailGame
            game={gameDetailInfo}
            urlTrailer={url}
            gameData={game}
          ></DetailGame>
        </div>
      </div>
      <div className="game-item-footer">
        <div className="game-item-footer-title">Get it now</div>
        <div className="game-item-footer-description">
          This game is published by {gameDetailInfo?.developer?.devId}
        </div>

        <div className="game-item-footer-store">
          {game?.urlAndroid != undefined && game?.urlAndroid != "" ? (
            <GooglePlayButton
              url={game.urlAndroid}
              theme={"dark"}
              className={"custom-style"}
            />
          ) : (
            <div></div>
          )}
          {game?.urlIOS != undefined && game?.urlIOS != "" ? (
            <AppStoreButton
              url={game.urlIOS}
              theme={"dark"}
              className={"custom-style"}
            />
          ) : (
            <div></div>
          )}
        </div>
        <div className="game-item-footer-outline"></div>
      </div>
    </div>
  );
}

export default GameItem;
