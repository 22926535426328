import React, { useEffect } from "react";
import FacebookFilled from "@ant-design/icons/FacebookFilled";
import MailFilled from "@ant-design/icons/MailFilled";
import YoutubeFilled from "@ant-design/icons/YoutubeFilled";
import AndroidFilled from "@ant-design/icons/AndroidFilled";

import { useNavigate } from "react-router-dom";
import "./style.css";
function TheFooter() {
  const navigate = useNavigate();
  let currentDate = new Date().getFullYear();
  return (
    <div
      className="footer-container"
      style={{ background: "var(--backDark-color)" }}
    >
      <div className="footer-content">
        <div className="logo-pirex-container">
          <div className="logo-pirex-inc"></div>
          <div className={"text-logo-footer"}>© {currentDate} Pirex Games,</div>
        </div>
        <div className="divide-bar-footer" style={{ background: "#454545" }} />
        <div className="footer-bottom">
          <div className="policy-container">
            <div className="policy-text">© {currentDate} Pirex Games.</div>
            <div
              className="policy-button policy-text"
              onClick={() => {
                navigate("/privacy-policy");
                window.scrollTo(0, 0);
              }}
            >
              Privacy policy
            </div>
          </div>

          <div className="logo-group-container">
            <a target="_blank" href="https://www.facebook.com/pirex.games">
              <FacebookFilled className={"logo-footer"} />
            </a>
            <a href="mailto:pirex.contact@gmail.com">
              <MailFilled className={"logo-footer"} />
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCnKrPUHpP4o2NqN6gjQF_tw"
            >
              <YoutubeFilled className={"logo-footer"} />
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/dev?id=6990335718751691198"
            >
              <AndroidFilled className={"logo-footer"} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TheFooter;
