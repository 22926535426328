import "./style.css";
import "../../styles/styleGlobal.css";

import OurGames from "../../components/OurGames/OurGames";
import games from "../../assets/data/Games.json";
import gamesVN from "../../assets/data/GamesVN.json";
import { GameDetailType, GameType } from "../../types";
import { Footer } from "antd/lib/layout/layout";
import TheFooter from "../../components/Footer/TheFooter";
import { Helmet } from "react-helmet-async";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";

type Props = {
  listGames: GameDetailType[];
};

function GamesScreen({ listGames }: Props) {
  const [cookies, setCookie] = useCookies(["Lang"]);
  const [dataGame, setDataGame] = useState<GameType[]>(gamesVN);
  useEffect(() => {
    if (cookies.Lang == null || cookies.Lang == undefined) {
      setCookie("Lang", 0);
      setDataGame(gamesVN);
    } else setDataGame(cookies.Lang == 0 ? gamesVN : games);
  }, [cookies]);
  return (
    <div className="content-container">
      <Helmet>
        <title>Pirex Games</title>
      </Helmet>
      <OurGames games={dataGame} listGameDetail={listGames}></OurGames>
      <Footer>
        <TheFooter />
      </Footer>
    </div>
  );
}

export default GamesScreen;
