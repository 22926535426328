import React from "react";
import "./style.css";
import "../../styles/styleGlobal.css";
import { Footer } from "antd/lib/layout/layout";
import TheFooter from "../../components/Footer/TheFooter";
import { Helmet } from "react-helmet-async";

function PrivacyPolicy() {
  const darkMode = true;
  return (
    <div className="content-container">
      <Helmet>
        <title>Privacy Policy | Pirex Games</title>
      </Helmet>
      <div className="content-container-policy">
        <div className="title">
          <h1 style={{ color: darkMode ? "white" : "black" }}>
            Privacy Policy
          </h1>
        </div>
        <div className="content-privacy-policy">
          <div className={darkMode ? "text-regular" : "text-regular-lm"}>
            This page is used to inform visitors regarding my policies with the
            collection, use, and disclosure of Personal Information if anyone
            decided to use my Service.
            <br />
            <br />
            If you choose to use my Service, then you agree to the collection
            and use of information in relation to this policy. The Personal
            Information that I collect is used for providing and improving the
            Service. I will not use or share your information with anyone except
            as described in this Privacy Policy.
          </div>
          <div className={darkMode ? "text-bold" : "text-bold-lm"}>
            Information Collection and Use
          </div>
          <div className={darkMode ? "text-regular" : "text-regular-lm"}>
            For a better experience, while using our Service, I may require you
            to provide us with certain personally identifiable information. The
            information that I request will be retained on your device and is
            not collected by me in any way.
            <br />
            <br />
            The app does use third party services that may collect information
            used to identify you.
            <br />
            <br />
            Link to privacy policy of third party service providers used by the
            app
            <br />
            <br />
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className={darkMode ? "text-theme-color" : "text-theme-color-lm"}
            >
              &ensp; ▷ Google Play Services
            </a>
            <br />
            <br />
            <a
              href="https://support.google.com/admob/answer/6128543?hl=en"
              target="_blank"
              rel="noopener noreferrer"
              className={darkMode ? "text-theme-color" : "text-theme-color-lm"}
            >
              &ensp; ▷ Google Admob
            </a>
          </div>
          <div className={darkMode ? "text-bold" : "text-bold-lm"}>
            Log Data
          </div>
          <div className={darkMode ? "text-regular" : "text-regular-lm"}>
            I want to inform you that whenever you use my Service, in a case of
            an error in the app I collect data and information (through third
            party products) on your phone called Log Data. This Log Data may
            include information such as your device Internet Protocol (“IP”)
            address, device name, operating system version, the configuration of
            the app when utilizing my Service, the time and date of your use of
            the Service, and other statistics.
          </div>
          <div className={darkMode ? "text-bold" : "text-bold-lm"}>Cookies</div>
          <div className={darkMode ? "text-regular" : "text-regular-lm"}>
            Cookies are files with a small amount of data that are commonly used
            as anonymous unique identifiers. These are sent to your browser from
            the websites that you visit and are stored on your device's internal
            memory.
            <br />
            <br />
            This Service does not use these “cookies” explicitly. However, the
            app may use third party code and libraries that use “cookies” to
            collect information and improve their services. You have the option
            to either accept or refuse these cookies and know when a cookie is
            being sent to your device. If you choose to refuse our cookies, you
            may not be able to use some portions of this Service.
          </div>
          <div className={darkMode ? "text-bold" : "text-bold-lm"}>
            Security
          </div>
          <div className={darkMode ? "text-regular" : "text-regular-lm"}>
            I value your trust in providing us your Personal Information, thus
            we are striving to use commercially acceptable means of protecting
            it. But remember that no method of transmission over the internet,
            or method of electronic storage is 100% secure and reliable, and I
            cannot guarantee its absolute security.
          </div>
          <div className={darkMode ? "text-bold" : "text-bold-lm"}>
            Links to Other Sites
          </div>
          <div className={darkMode ? "text-regular" : "text-regular-lm"}>
            This Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by me. Therefore, I strongly advise
            you to review the Privacy Policy of these websites. I have no
            control over and assume no responsibility for the content, privacy
            policies, or practices of any third-party sites or services.
          </div>
          <div className={darkMode ? "text-bold" : "text-bold-lm"}>
            Children’s Privacy
          </div>
          <div className={darkMode ? "text-regular" : "text-regular-lm"}>
            These Services do not address anyone under the age of 13. I do not
            knowingly collect personally identifiable information from children
            under 13. In the case I discover that a child under 13 has provided
            me with personal information, I immediately delete this from our
            servers. If you are a parent or guardian and you are aware that your
            child has provided us with personal information, please contact me
            so that I will be able to do necessary actions.
          </div>
          <div className={darkMode ? "text-bold" : "text-bold-lm"}>
            Changes to This Privacy Policy
          </div>
          <div className={darkMode ? "text-regular" : "text-regular-lm"}>
            I may update our Privacy Policy from time to time. Thus, you are
            advised to review this page periodically for any changes. I will
            notify you of any changes by posting the new Privacy Policy on this
            page. These changes are effective immediately after they are posted
            on this page.
          </div>
          <div className={darkMode ? "text-bold" : "text-bold-lm"}>
            Contact Us
          </div>
          <div className={darkMode ? "text-regular" : "text-regular-lm"}>
            If you have any questions or suggestions about my Privacy Policy, do
            not hesitate to contact me.
          </div>
        </div>
      </div>
      <Footer>
        <TheFooter />
      </Footer>
    </div>
  );
}

export default PrivacyPolicy;
